var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"postedLayers-container"},[_c('div',{staticClass:"layers-container"},[_c('div',{staticClass:"header-container"},[_c('ol',{staticClass:"postedLayers-list-container"},[_vm._l((_vm.tocPostedLayers),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"posted-layer-container"},[(!item.isWMS)?_c('div',[_c('div',{staticClass:"layer-details-container"},[_c('div',{staticClass:"layer-details-info"},[_c('div',{staticClass:"layer-info-name"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                                                    'collapse-' + index
                                                ),expression:"\n                                                    'collapse-' + index\n                                                "}],staticClass:"collapse-button",class:'collapse-' + index,attrs:{"variant":"light"}},[_c('img',{staticClass:"expanded",attrs:{"width":"17.3px","height":"17.3px","src":require("../../assets/minus.svg")}}),_c('img',{staticClass:"collapsed",attrs:{"width":"17.3px","height":"17.3px","src":require("../../assets/plus_sign.svg")}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.Visible),expression:"item.Visible"}],staticClass:"checkBox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.Visible)?_vm._i(item.Visible,null)>-1:(item.Visible)},on:{"click":function($event){return _vm.togglePostedLayer(index)},"change":function($event){var $$a=item.Visible,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "Visible", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "Visible", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "Visible", $$c)}}}}),_c('span',{staticClass:"layer-name-symbol",domProps:{"innerHTML":_vm._s(_vm.layerSymbol(item))}}),_c('h5',{staticClass:"layer-name-title",attrs:{"title":item.DisplayName}},[_vm._v(" "+_vm._s(item.DisplayName)+" ")])]),_c('div',{staticClass:"layer-details-location"},[_c('button',{staticClass:"layer-zoom",on:{"click":function($event){return _vm.zoomToPosted(
                                                        item
                                                            .MarkerSettings[0]
                                                            .dataSource
                                                    )}}},[_c('p',[_vm._v("Go to Location")])])])]),_c('div',{staticClass:"layer-actions-control"},[_c('button',{staticClass:"btn-side",on:{"click":function($event){return _vm.$emit('edit', {
                                                    layerId: item.id,
                                                    layerIndex: index,
                                                    layerType: 'posted',
                                                })}}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"btn-edit",attrs:{"title":"Edit layer","src":require("../../assets/EM_edit.svg")}})]),_c('button',{staticClass:"btn-side",on:{"click":function($event){return _vm.showDeleteModal(
                                                    item,
                                                    index,
                                                    'posted'
                                                )}}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"btn-delete",attrs:{"width":"40px","height":"40px","title":"Delete layer","src":require("../../assets/delete_icon-dark.svg")}})])])]),_c('b-collapse',{staticClass:"layer-details-collapse",attrs:{"id":'collapse-' + index}},[_c('div',{staticClass:"layer-details-controls"},[_c('div',{staticClass:"checkboxCol layer-control-label"},[_c('label',{staticClass:"rowData subFont"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                        item
                                                            .MarkerSettings[0]
                                                            .dataSource
                                                            .features[0]
                                                            .properties[
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                                .labelVisibleIndex
                                                        ].label
                                                    ),expression:"\n                                                        item\n                                                            .MarkerSettings[0]\n                                                            .dataSource\n                                                            .features[0]\n                                                            .properties[\n                                                            item\n                                                                .MarkerSettings[0]\n                                                                .dataSource\n                                                                .labelVisibleIndex\n                                                        ].label\n                                                    "}],staticClass:"checkBox mr-2",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                                        item
                                                            .MarkerSettings[0]
                                                            .dataSource
                                                            .features[0]
                                                            .properties[
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                                .labelVisibleIndex
                                                        ].label
                                                    )?_vm._i(
                                                        item
                                                            .MarkerSettings[0]
                                                            .dataSource
                                                            .features[0]
                                                            .properties[
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                                .labelVisibleIndex
                                                        ].label
                                                    ,null)>-1:(
                                                        item
                                                            .MarkerSettings[0]
                                                            .dataSource
                                                            .features[0]
                                                            .properties[
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                                .labelVisibleIndex
                                                        ].label
                                                    )},on:{"click":function($event){return _vm.togglePostedLayerLabels(
                                                            index
                                                        )},"change":function($event){var $$a=
                                                        item
                                                            .MarkerSettings[0]
                                                            .dataSource
                                                            .features[0]
                                                            .properties[
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                                .labelVisibleIndex
                                                        ].label
                                                    ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item
                                                            .MarkerSettings[0]
                                                            .dataSource
                                                            .features[0]
                                                            .properties[
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                                .labelVisibleIndex
                                                        ], "label", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item
                                                            .MarkerSettings[0]
                                                            .dataSource
                                                            .features[0]
                                                            .properties[
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                                .labelVisibleIndex
                                                        ], "label", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item
                                                            .MarkerSettings[0]
                                                            .dataSource
                                                            .features[0]
                                                            .properties[
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                                .labelVisibleIndex
                                                        ], "label", $$c)}}}}),_vm._v(" Label ")])]),_c('div',{staticClass:" layer-control-cluster"},[_c('label',{staticClass:"rowData subFont"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.clustered),expression:"item.clustered"}],staticClass:"checkBox mr-2",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.clustered)?_vm._i(item.clustered,null)>-1:(item.clustered)},on:{"click":function($event){return _vm.togglePostedLayerClustered(
                                                            index
                                                        )},"change":function($event){var $$a=item.clustered,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "clustered", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "clustered", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "clustered", $$c)}}}}),_vm._v("Cluster ")])])])])],1):_c('div',[_c('div',{staticClass:"layer-details-container layer-container-wms"},[_c('div',{staticClass:"layer-details-info"},[_c('div',{staticClass:"layer-info-name"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.visible),expression:"item.visible"}],staticClass:"checkBox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.visible)?_vm._i(item.visible,null)>-1:(item.visible)},on:{"click":function($event){return _vm.togglePostedWMSLayer(
                                                        item
                                                    )},"change":function($event){var $$a=item.visible,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "visible", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "visible", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "visible", $$c)}}}}),_c('span',{staticClass:"layer-name-symbol contour",domProps:{"innerHTML":_vm._s(_vm.layerSymbol(item))}}),_c('h5',{staticClass:"layer-name-title",attrs:{"title":item.ContourOptions
                                                        .DisplayName}},[_vm._v(" "+_vm._s(item.ContourOptions .DisplayName)+" ")])]),_c('div',{staticClass:"layer-details-location contour"},[_c('button',{staticClass:"layer-zoom",on:{"click":function($event){return _vm.zoomToContour(
                                                        item.jobID
                                                    )}}},[_c('p',[_vm._v("Go to Location")])])])]),_c('div',{staticClass:"layer-actions-control"},[_c('div',[_c('button',{staticClass:"btn-side",on:{"click":function($event){return _vm.$emit('edit', {
                                                        layerId: item.jobID,
                                                        layerIndex: index,
                                                        layerType:
                                                            'contour',
                                                    })}}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"btn-edit",attrs:{"title":"Edit layer","src":require("../../assets/EM_edit.svg")}})])]),_c('div',[_c('button',{staticClass:"btn-side",on:{"click":function($event){return _vm.showDeleteModal(
                                                        item,
                                                        index,
                                                        'contour'
                                                    )}}},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",modifiers:{"hover":true,"left":true}}],staticClass:"btn-delete",attrs:{"width":"40px","height":"40px","title":"Delete layer","src":require("../../assets/delete_icon-dark.svg")}})])])])])])])])}),(_vm.tocPostedLayers.length < 1)?_c('div',{staticClass:"layer-details-empty"},[_c('p',[_vm._v(" No user posted layers found ")])]):_vm._e()],2),_c('div',[_c('b-modal',{ref:"deletePostedLyr-modal",staticClass:"model_style",attrs:{"hide-header":"","hide-footer":"","centered":""}},[_c('div',{staticClass:"d-block text-center"},[_c('div',{staticClass:"text-right cursor-pointer"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.hideDeleteModal}},[_c('img',{attrs:{"src":require("../../assets/close_icon.svg")}})])],1),_c('h5',{staticClass:"mt-2"},[_vm._v(" Delete "+_vm._s(_vm.modalLayerName)+" ")]),_c('small',[_vm._v("Are you sure you want to delete this layer? This action cannot be undone.")]),_c('br'),_c('div',{staticClass:"mt-3 mb-4 delModal"},[_c('a',{staticClass:"text-primary",on:{"click":_vm.hideDeleteModal}},[_vm._v("No, don't delete")]),_c('button',{staticClass:"del-btn ml-2",on:{"click":function($event){return _vm.deletePostedLyr()}}},[_vm._v(" Delete Layer ")])])])])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }