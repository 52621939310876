<template>
    <div>
        <div class="postedLayers-container">
            <div class="layers-container">
                <div class="header-container">
                    <!-- this is here for debugging -->
                    <!-- <button class="btn-header" @click="deleteAllPostedLayers">
                        delete all
                    </button> -->
                    <ol class="postedLayers-list-container">
                        <li
                            v-for="(item, index) in tocPostedLayers"
                            :key="index"
                        >
                            <div class="posted-layer-container">
                                <div v-if="!item.isWMS">
                                    <div class="layer-details-container">
                                        <div class="layer-details-info">
                                            <div class="layer-info-name">
                                                <button
                                                    v-b-toggle="
                                                        'collapse-' + index
                                                    "
                                                    :class="'collapse-' + index"
                                                    class="collapse-button"
                                                    variant="light"
                                                >
                                                    <img
                                                        width="17.3px"
                                                        height="17.3px"
                                                        class="expanded"
                                                        src="../../assets/minus.svg"
                                                    />
                                                    <img
                                                        width="17.3px"
                                                        height="17.3px"
                                                        class="collapsed"
                                                        src="../../assets/plus_sign.svg"
                                                    /></button
                                                ><input
                                                    type="checkbox"
                                                    class="checkBox"
                                                    v-model="item.Visible"
                                                    @click="
                                                        togglePostedLayer(index)
                                                    "
                                                />

                                                <span
                                                    class="layer-name-symbol"
                                                    v-html="layerSymbol(item)"
                                                ></span>
                                                <!-- Should be changed to DisplayName -->
                                                <h5
                                                    class="layer-name-title"
                                                    :title="item.DisplayName"
                                                >
                                                    {{ item.DisplayName }}
                                                </h5>
                                            </div>
                                            <div class="layer-details-location">
                                                <button
                                                    class="layer-zoom"
                                                    @click="
                                                        zoomToPosted(
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                        )
                                                    "
                                                >
                                                    <p>Go to Location</p>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="layer-actions-control">
                                            <button
                                                class="btn-side"
                                                @click="
                                                    $emit('edit', {
                                                        layerId: item.id,
                                                        layerIndex: index,
                                                        layerType: 'posted',
                                                    })
                                                "
                                            >
                                                <img
                                                    class="btn-edit"
                                                    v-b-tooltip.hover.left
                                                    title="Edit layer"
                                                    src="../../assets/EM_edit.svg"
                                                />
                                            </button>
                                            <button
                                                class="btn-side"
                                                @click="
                                                    showDeleteModal(
                                                        item,
                                                        index,
                                                        'posted'
                                                    )
                                                "
                                            >
                                                <img
                                                    class="btn-delete"
                                                    width="40px"
                                                    height="40px"
                                                    v-b-tooltip.hover.left
                                                    title="Delete layer"
                                                    src="../../assets/delete_icon-dark.svg"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <b-collapse
                                        :id="'collapse-' + index"
                                        class="layer-details-collapse"
                                    >
                                        <div class="layer-details-controls">
                                            <div
                                                class="checkboxCol layer-control-label"
                                            >
                                                <label class="rowData subFont">
                                                    <input
                                                        type="checkbox"
                                                        class="checkBox mr-2"
                                                        v-model="
                                                            item
                                                                .MarkerSettings[0]
                                                                .dataSource
                                                                .features[0]
                                                                .properties[
                                                                item
                                                                    .MarkerSettings[0]
                                                                    .dataSource
                                                                    .labelVisibleIndex
                                                            ].label
                                                        "
                                                        @click="
                                                            togglePostedLayerLabels(
                                                                index
                                                            )
                                                        "
                                                    />

                                                    Label
                                                </label>
                                            </div>
                                            <div class=" layer-control-cluster">
                                                <label class="rowData subFont">
                                                    <input
                                                        type="checkbox"
                                                        class="checkBox mr-2"
                                                        v-model="item.clustered"
                                                        @click="
                                                            togglePostedLayerClustered(
                                                                index
                                                            )
                                                        "
                                                    />Cluster
                                                </label>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                                <div v-else>
                                    <div
                                        class="layer-details-container layer-container-wms"
                                    >
                                        <div class="layer-details-info">
                                            <div class="layer-info-name">
                                                <input
                                                    type="checkbox"
                                                    class="checkBox"
                                                    v-model="item.visible"
                                                    @click="
                                                        togglePostedWMSLayer(
                                                            item
                                                        )
                                                    "
                                                />
                                                <span
                                                    class="layer-name-symbol contour"
                                                    v-html="layerSymbol(item)"
                                                ></span>
                                                <!-- Should be changed to DisplayName -->
                                                <h5
                                                    class="layer-name-title"
                                                    :title="
                                                        item.ContourOptions
                                                            .DisplayName
                                                    "
                                                >
                                                    {{
                                                        item.ContourOptions
                                                            .DisplayName
                                                    }}
                                                </h5>
                                            </div>
                                            <div
                                                class="layer-details-location contour"
                                            >
                                                <button
                                                    class="layer-zoom"
                                                    @click="
                                                        zoomToContour(
                                                            item.jobID
                                                        )
                                                    "
                                                >
                                                    <p>Go to Location</p>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="layer-actions-control">
                                            <div>
                                                <button
                                                    class="btn-side"
                                                    @click="
                                                        $emit('edit', {
                                                            layerId: item.jobID,
                                                            layerIndex: index,
                                                            layerType:
                                                                'contour',
                                                        })
                                                    "
                                                >
                                                    <img
                                                        class="btn-edit"
                                                        v-b-tooltip.hover.left
                                                        title="Edit layer"
                                                        src="../../assets/EM_edit.svg"
                                                    />
                                                </button>
                                            </div>

                                            <div>
                                                <button
                                                    class="btn-side"
                                                    @click="
                                                        showDeleteModal(
                                                            item,
                                                            index,
                                                            'contour'
                                                        )
                                                    "
                                                >
                                                    <img
                                                        class="btn-delete"
                                                        width="40px"
                                                        height="40px"
                                                        v-b-tooltip.hover.left
                                                        title="Delete layer"
                                                        src="../../assets/delete_icon-dark.svg"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <div
                            v-if="tocPostedLayers.length < 1"
                            class="layer-details-empty"
                        >
                            <p>
                                No user posted layers found
                            </p>
                        </div>
                    </ol>
                    <div>
                        <b-modal
                            ref="deletePostedLyr-modal"
                            class="model_style"
                            hide-header
                            hide-footer
                            centered
                        >
                            <div class="d-block text-center">
                                <div class="text-right cursor-pointer">
                                    <b-button
                                        @click="hideDeleteModal"
                                        variant="light"
                                    >
                                        <img
                                            src="../../assets/close_icon.svg"
                                        />
                                    </b-button>
                                </div>
                                <h5 class="mt-2">
                                    Delete {{ modalLayerName }}
                                </h5>
                                <small
                                    >Are you sure you want to delete this layer?
                                    This action cannot be undone.</small
                                ><br />
                                <div class="mt-3 mb-4 delModal">
                                    <a
                                        class="text-primary"
                                        @click="hideDeleteModal"
                                        >No, don't delete</a
                                    >
                                    <button
                                        class="del-btn ml-2"
                                        @click="deletePostedLyr()"
                                    >
                                        Delete Layer
                                    </button>
                                </div>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { spatialFunctions } from '../../utilities/spatialFunctions';
import { mapLayerFunctions } from '../../utilities/mapLayerFunctions';
import { mapMarkerFunctions } from '../../utilities/mapMarkerFunctions';
import { latLngBounds } from 'leaflet';
export default {
    name: 'PostedLayers',
    data() {
        return {
            modalLayerIndex: null,
            modalLayerId: null,
            modalLayerName: null,
            modalLayerType: null,
        };
    },
    methods: {
        togglePostedLayer(index) {
            this.$store.dispatch('projects/updatePostedVisibility', index);
        },
        togglePostedLayerLabels(index) {
            this.$store.dispatch('projects/togglePostedLayerLabels', index);
        },
        togglePostedLayerClustered(index) {
            this.$store.dispatch('projects/togglePostedLayerClusteredAction', {
                index,
            });
        },
        zoomToPosted(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer.features.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    location.geometry.coordinates[0].forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'Point') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(minX, parseFloat(xCoord));
                        maxX = Math.max(maxX, parseFloat(xCoord));
                        minY = Math.min(minY, parseFloat(yCoord));
                        maxY = Math.max(maxY, parseFloat(yCoord));
                    }
                } else if (location.geometry.type == 'LineString') {
                    location.geometry.coordinates.forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                }
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;

            let bufferPercentage = 10;
            this.$store.dispatch(
                'oneMap/setZoomToExtent',
                latLngBounds([
                    [
                        spatialFunctions.correctLatitude(
                            minY - (yRange * bufferPercentage) / 100
                        ),
                        spatialFunctions.correctLongitude(
                            minX - (xRange * bufferPercentage) / 100
                        ),
                    ],
                    [
                        spatialFunctions.correctLatitude(
                            maxY + (yRange * bufferPercentage) / 100
                        ),
                        spatialFunctions.correctLongitude(
                            maxX + (xRange * bufferPercentage) / 100
                        ),
                    ],
                ])
            );
        },
        zoomToContour(jobId) {
            mapLayerFunctions.getContoursExtent(jobId, (bounds) => {
                if (bounds !== null) {
                    this.$store.dispatch('oneMap/setZoomToExtent', bounds);
                }
            });
        },
        togglePostedWMSLayer(item) {
            this.$store.commit('projects/updatePostedWMSVisibility', item);
        },
        showDeleteModal(layerDetails, index, type) {
            this.modalLayerIndex = index;
            this.modalLayerId = layerDetails.postingId;
            this.modalLayerName = layerDetails.DisplayName;
            this.modalLayerType = type;
            this.$refs['deletePostedLyr-modal'].show();
        },
        hideDeleteModal() {
            this.$refs['deletePostedLyr-modal'].hide();
        },
        deletePostedLyr() {
            this.$store.dispatch('projects/deletePostedLayer', {
                id: this.modalLayerId,
                lyrType: this.modalLayerType,
            });
            this.$refs['deletePostedLyr-modal'].hide();
        },
        deleteAllPostedLayers() {
            this.$store.commit('projects/deleteAllPostedLayers');
        },
        layerSymbol(layer) {
            if (!layer.isWMS) {
                return mapMarkerFunctions.getIcon(
                    layer.Symbology,
                    layer.SymbologyColor,
                    layer.PointOutlineColor
                );
            } else {
                return mapLayerFunctions.getLineIcon(
                    layer.ContourOptions.LineColor,
                    layer.ContourOptions.LineWidth,
                    layer.ContourOptions.LineStyle &&
                        layer.ContourOptions.LineStyle.toLowerCase() ==
                            'dashed',
                    layer.ContourOptions.LineOpacity
                );
            }
        },
    },

    computed: {
        ...mapGetters('projects', {
            tocPostedLayers: 'tocPostedLayers',
        }),
    },
};
</script>
<style lang="scss">
[class^='collapse'][aria-expanded='false'] .expanded {
    display: none;
}

[class^='collapse'][aria-expanded='true'] .collapsed {
    display: none;
}
.collapse-button {
    width: 30px;
    height: 21px;
    border-radius: 4px;
    background-color: white;
    border: none;
    padding-right: 5px;
    padding-left: 0;
    justify-self: center;
    justify-content: center;
}

ul {
    padding: 0;
}
.layer-zoom {
    background-color: transparent;
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 20px;
    color: #006395;
    min-width: 117px;
    max-height: 1.5rem;
}
.postedLayers-list-container {
    padding-left: 7px;
}
.postedLayers-list-container li:first-child .posted-layer-container {
    border: none;
}
.layer-details-controls {
    padding-left: 28px;
    margin: 5px 0 5px 39px;
}
.layer-control-cluster {
    border-top: 1px dashed #d1d1d1;
}
.posted-layer-container {
    border-top: 1px solid #d1d1d1;
    width: 100%;
}
.layer-details-collapse {
    font: normal normal normal 16px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #212121;
    border-top: 1px dashed #d1d1d1;
}
.layer-details-info {
    display: flex;
    flex-flow: column wrap;
    margin-bottom: 2px;
    align-self: center;
}
.layer-info-name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.layer-details-container {
    display: flex;
    flex-flow: row nowrap;
    min-height: 56px;
}
.layer-actions-control {
    position: relative;
    display: flex;
    flex-flow: row wrap;
}
.layer-actions-control button {
    padding: 0 !important;
    background-color: transparent;
    border: none;
    height: 30px;
    width: 30px;
    margin: 0;
}
.btn-delete {
    transform: translate(-5px, -5px);
}
.layer-actions-control button:hover {
    background-color: lightgrey;
    border-radius: 5px;
}
.layer-name-symbol {
    padding-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
}
.layer-name-symbol.contour {
    padding-left: 8px;
    margin-top: auto;
    margin-bottom: auto;
}
.layer-details-empty {
    font-style: italic;
    font-size: 16px;
    margin-bottom: 6px;
}
.layer-name-title {
    max-width: 220px;
    padding-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    font: normal normal 500 16px/20px IBM Plex Sans;
    letter-spacing: 0px;
    color: #212121;
}
@media only screen and (max-width: 1800px) {
    .layer-name-title {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.layer-details-location {
    margin-left: 53px;
}
.layer-details-location.contour {
    margin-left: 22px;
}

.layer-control-label,
.layer-control-cluster {
    padding: 10px 0;
}
.layer-details-controls label {
    margin: 0 !important;
}
.layer-details-controls input {
    vertical-align: text-bottom;
}
.postedLayers-container {
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
}
.layers-container {
    padding: 0 27px 0 19px;
    margin-top: 5px;
}
.layer-container-wms {
    margin-left: 32px;
}
li {
    list-style: none;
}
.posted-layer-container .layer-details-container {
    justify-content: space-between;
}
</style>
